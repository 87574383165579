/* istanbul ignore file */
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider, useParams } from 'react-router-dom';

import "@aurodesignsystem/auro-icon/dist/auro-icon__bundled.js";
import "@aurodesignsystem/auro-alert/dist/auro-alert__bundled.js";
import "@aurodesignsystem/auro-input/dist/auro-input__bundled.js";
import "@aurodesignsystem/auro-select/dist/auro-select__bundled.js";
import "@aurodesignsystem/auro-dropdown/dist/auro-dropdown__bundled.js";
import "@aurodesignsystem/auro-menu/dist/auro-menu__bundled.js";
import "@aurodesignsystem/auro-button/dist/auro-button__bundled.js";
import "@aurodesignsystem/auro-loader/dist/auro-loader__bundled.js";
import "@aurodesignsystem/auro-combobox/dist/auro-combobox__bundled.js";
import "@aurodesignsystem/auro-lockup/dist/auro-lockup__bundled.js";

import ErrorPage from './error-page';
import './index.scss';
import { MakePayment } from './routes/make-payment';
import { DependencyContainer } from './plumbing/DependencyContainer';
import { ServiceFactory } from './plumbing/ServiceFactory';
import { IPaymentInformationService, PaymentInformationService } from './services/payment-information-service';
import { TransactionProcessor, ITransactionProcessor } from './services/transaction-processor';
import { CartService, ICartService } from './services/cart-service';
import { SeatUpgradeSummary } from './routes/seat-upgrade/seat-upgrade-summary';
import { DisplayService, IDisplayService } from './services/seat-upgrade/display-service';
import { SeatUpgradePayment } from './routes/seat-upgrade/seat-upgrade-payment';
import { IPaymentService, PaymentService } from './services/seat-upgrade/payment-service';
import { ConfirmationService, IConfirmationService } from './services/seat-upgrade/confirmation-service';
import { SeatUpgradeConfirmation } from './routes/seat-upgrade/seat-upgrade-confirmation';
import { SeatUpgradeTimeout } from './routes/seat-upgrade/seat-upgrade-timeout';
import { NotFoundErrorPage } from './routes/make-payment/not-found-error-page';
import { ErrorBoundary } from "react-error-boundary";
/*
 * https://stackoverflow.com/a/71000352
 * This is a temporary hack to bounce the /v2/ routes to root
 */
interface TxRedirectProps {
  route: string;
}

/* istanbul ignore next */
const TxRedirect = (props: TxRedirectProps) => {
  const { tx } = useParams();
  return <Navigate to={`${props.route}/${tx}`} replace />
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <div>Nothing to see here</div>,
      errorElement: <ErrorPage />
    },
    {
      path: 'transaction/:tx',
      element:  <ErrorBoundary FallbackComponent={NotFoundErrorPage} >
                  <MakePayment />
                </ErrorBoundary>
    },
    {
      path: 'seat-upgrade-summary/:tx',
      element: <SeatUpgradeSummary />
    },
    {
      path: 'seat-upgrade-payment/:tx',
      element: <SeatUpgradePayment />
    },
    {
      path: 'seat-upgrade-confirmation/:tx',
      element: <SeatUpgradeConfirmation />
    },
    {
      path: 'seat-upgrade-timeout',
      element: <SeatUpgradeTimeout />
    }
  ]
);

let paymentInformationService: IPaymentInformationService | undefined = undefined;
let transactionProcessor: ITransactionProcessor | undefined = undefined;
let cartService: ICartService | undefined = undefined;
let displayService: IDisplayService | undefined = undefined;
let paymentService: IPaymentService | undefined = undefined;
let confirmationService: IConfirmationService | undefined = undefined;

const serviceFactory: ServiceFactory = {
  GetPaymentInformationService: function (): IPaymentInformationService {
    if (!paymentInformationService) {
      paymentInformationService = new PaymentInformationService();
    }

    return paymentInformationService;
  },
  GetTransactionProcessor: function (): ITransactionProcessor {
    if (!transactionProcessor) {
      transactionProcessor = new TransactionProcessor();
    }

    return transactionProcessor;
  },
  GetCartService: function (): ICartService {
    if (!cartService) {
      cartService = new CartService();
    }

    return cartService;
  },
  GetDisplayService: function (): IDisplayService {
    if(!displayService) {
      displayService = new DisplayService();
    }

    return displayService;
  },
  GetPaymentService: function (): IPaymentService{
    if(!paymentService) {
      paymentService = new PaymentService();
    }
    return paymentService;
  },
  GetConfirmationService: function (): IConfirmationService {
    if(!confirmationService) {
      confirmationService = new ConfirmationService();
    }
    return confirmationService;
  }
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <DependencyContainer serviceFactory={serviceFactory}>
    <RouterProvider router={router} />
  </DependencyContainer>
);
